body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ListName {
  font-family: "Helvetica Neue";
  font-weight: 300;
  font-size: 30px;
  text-align: center;
  color: #373a3c;
}

.ListDepartmentName {
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: 17px;
  text-align: center;
  color: #373a3c;
}

.ListDay {
  font-family: "Helvetica Neue";
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  color: #000;
}

.textLink {
  font-size: 22px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

